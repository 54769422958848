import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "dark-mode"
    }}>{`Dark Mode`}</h1>
    <p>{`We bring already configured a theme of dark mode.
The exchange of variables is done in two situations:`}</p>
    <ol>
      <li parentName="ol">{`By user's browser preference`}</li>
      <li parentName="ol">{`With a class in the body`}</li>
    </ol>
    <h2 {...{
      "id": "1-by-users-browser-preference"
    }}>{`1. By user's browser preference`}</h2>
    <p>{`The user doesn't need to do anything, the change will be done automatically according to the `}<inlineCode parentName="p">{`prefers-color-scheme`}</inlineCode>{` css rule`}</p>
    <h2 {...{
      "id": "2-with-a-class-in-the-body"
    }}>{`2. With a class in the body`}</h2>
    <p>{`If there is a need to lock this automatic switch or make a theme selector for the user, just add a class in the html for their respective themes:`}</p>
    <blockquote>
      <p parentName="blockquote">{`To dark mode`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html class="dark">
</html
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`To light mode`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html class="light">
</html
`}</code></pre>
    <h2 {...{
      "id": "variables"
    }}>{`Variables`}</h2>
    <p>{`List of variables that change with the change of theme if there is a need to use it in a custom component, these are the variables changed in the change of the theme that reflect in all the components of the framework`}</p>
    <pre><code parentName="pre" {...{}}>{`  --background
  --background_secondary
  --text_color
  --title_color
  --background_third
  --background_reset
  --shadow_color
  --box_shadow
  --border_color
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      